import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
//     {
//   path: '*',
//   redirect: '/'
// },
  {
  path: '/',
  name: 'PageView',
  component: () => import('@/views/PageView'),
  children: [
    {
      path: '/',
      redirect: '/home'
    }, {
      path: '/home',
      name: 'home',
      component: () => import('@/views/home/HomePage'),
      meta: {
        title: '首页',noCache: true
      }
    }, {
      path: '/product',
      name: 'product',
      component: () => import('@/views/product'),
      // redirect: '/product',
      meta: { title: '售电套餐',noCache: true},
      // children: [
        // {
        //   path: '/product/product1',
        //   name: 'product1',
        //   component: () => import('@/views/product/Software_smartTown'),
        //   meta: {
        //     title: '售电套餐丨售电套餐1'
        //   }
        // },
        // {
        //   path: '/product/product2',
        //   name: 'product2',
        //   component: () => import('@/views/product/Software_bigData'),
        //   meta: {
        //     title: '售电套餐丨售电套餐2'
        //   }
        // }
      // ]
    }, {
      path: '/service',
      name: 'service',
      component: () => import('@/views/service/Service'),
      meta: {
        title: '公司业务'
      }
    },
    {
      path: '/servicedetail',
      name: 'servicedetail',
      component: () => import('@/views/service/ServiceDetail'),
      meta: {title: '业务详情',noCache: true}
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('@/views/newsInfo/NewsInfo'),
      meta: {title: '新闻动态'},
    },
    {
      path: '/notice',
      name: 'notice',
      component: () => import('@/views/newsInfo/NewsNotice'),
      meta: {title: '公司公告'},
    },
    {
      path: '/newsdetail',
      name: 'NewsDetail',
      component: () => import('@/views/newsInfo/NewsDetail'),
      meta: {
        title: '新闻内容',noCache: true
      }
    },
    {
      path: '/aboutus',
      name: 'aboutus',
      component: () => import('@/views/PageView'),
      redirect: '/aboutus/companyintroduction',
      meta: { title: '关于我们'},
      children: [
        {
          path: '/aboutus/companyintroduction',
          name: 'companyintroduction',
          component: () => import('@/views/aboutUs/CompanyIntroduction'),
          meta: {
            title: '公司介绍'
          }
        },
        {
          path: '/aboutus/companydevelop',
          name: 'companydevelop',
          component: () => import('@/views/aboutUs/CompanyDevelop'),
          meta: {
            title: '公司发展'
          }
        }
      ]
    },
    {
      path: '/jobchance',
      name: 'jobchance',
      component: () => import('@/views/job/JobChance'),
      meta: {
        title: '工作机会'
      }
    },
    {
      path: '/jobsubmit',
      name: 'jobsubmit',
      component: () => import('@/views/job/JobSubmit'),
      meta: {
        title: '工作机会-简历',noCache: true
      }
    },
    {
      path: '/contactus',
      name: 'contactus',
      component: () => import('@/views/contactUs/ContactUs'),
      meta: {
        title: '联系我们'
      }
    }
  ]
},
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/HomePage')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
