

import request from '@/api/index'


// 获取验证码
export const getCaptcha = (param) => {
  return request.get('/login/captcha', param)
};


// 获取站点配置
export const getConfig = (param) => {
  return request.get('/c/site/config/list', param)
};


// 获取轮播图
export const getSwiper = (param) => {
  return request.get('/c/site/swiper/list', param)
};

// 获取公司业务列表
export const getServiceList = (param) => {
  return request.get('/c/site/service/list', param)
};
// 获取公司业务详情
export const getService = (param) => {
  return request.get('/c/site/service', param)
};



// 获取套餐列表
export const getComboList = (param) => {
  return request.get('/c/site/combo/list', param)
};
// 获取套餐详情
export const getCombo = (param) => {
  return request.get('/c/site/combo', param)
};

// 获取客户评价
export const getEvaluationList = (param) => {
  return request.get('/c/site/customer/evaluation/list', param)
};


// 获取资讯分类
export const getInfoTypeList = (param) => {
  return request.get('/c/site/info-type/list', param)
};
// 获取资讯列表
export const getInfoList = (param) => {
  return request.get('/c/site/info/list', param)
};
// 获取资讯详情
export const getInfo = (param) => {
  return request.get('/c/site/info', param)
};



// 获取公告列表
export const getNoticeList = (param) => {
  return request.get('/c/site/notice/list', param)
};
// 获取公告详情
export const getNotice = (param) => {
  return request.get('/c/site/notice', param)
};



// 获取公司历程
export const getProgressList = (param) => {
  return request.get('/c/site/progress/list', param)
};

// 获取招聘信息
export const getRecruitList = (param) => {
  return request.get('/c/site/recruit/list', param)
};

// 提交简历
export const postRecruitFeedback = (param) => {
  return request.postUpload('/c/site/recruit-feedback', param)
};

// 提交业务需求
export const postBusinessFeedback = (param) => {
  return request.post('/c/site/business-feedback', param)
};
