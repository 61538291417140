import request from '@/api/index'

// 应用 公共api

// 文件上传
export const uploadFile = (param, callback = null) => {
  return request.postUpload('/file', param, callback)
}

// 文件上传
export function importFiles(params, callback) {
  const url = '/file';
  return request.uploadFile(url, params, callback)
}

// 下载文件
export const downloadFile = (param) => {
  return request.download('/file/d', param)
}

const appBaseUrl = process.env.VUE_APP_BASE_API.replace(/\/api[\/]?$/, '')
// 预览文件，返回完整URL地址
export const previewFile = (uri) => {
  if (!uri){
    return null
  }
  // return process.env.VUE_APP_BASE_API + '/file/d?uri=' + encodeURIComponent(uri)

  return appBaseUrl + '/uploads/' + uri
}
