<template>
  <div id="CallCenter" >
    <chat-wechat  @closeWindow="closeWindow" class="window"  :class="{ 'show':show }"></chat-wechat>
    <div class="call-center-button" :class="{ 'show':!hidden }">
      <svg-icon icon-class="在线客服" class=" pointer" @mouseenter="clickCall(true)" @mouseleave = "clickCall(false)"></svg-icon>
      <div style="text-align: center;">客服</div>
      <i class="el-icon-arrow-right pointer" @click="clickHidden(true)"></i>
    </div>

    <div class="call-center-button-hidden pointer" @click="clickHidden(false)" :class="{ 'show':hidden }">
      <i class="el-icon-arrow-left" ></i>
    </div>


  </div>
</template>

<script>


  import chatWechat from './components/chatWechat'
  export default {
    name: "callCenter",
    data(){
      return{
        show:false,
        hidden:false,
      }
    },
    components:{
      chatWechat
    },

    methods:{
      clickCall(val){
        this.show =  val;
      },

      closeWindow(){
        this.show = false;
      },
      clickHidden(val){
        this.hidden = val;
      }

    }




  }


</script>

<style lang="scss" scoped>
#CallCenter{
  .window{

    z-index: 200;
    position: fixed;
    right: 10px;
    bottom: 260px;

    transform:translateX(300px);
    transition: transform 0.3s ease-in-out 0s;

    &.show{
      transform:translateX(0);
    }
  }
  .call-center-button{
    color:#034bf4;
    z-index: 200;
    position: fixed;
    right: 10px;
    bottom: 100px;
    /*width: 100px;*/
    background: rgba(248,249,255,0.5);
    box-shadow: 0 10px 30px rgb(96 131 254 / 7%), 0 4px 8px rgb(96 131 254 / 4%), 0 2px 4px rgb(96 131 254 / 3%);
    border-radius: 8px;
    backdrop-filter: blur(10px);
    border: 1px solid #dfe7f9;

    /*border: 1px solid rgba(182, 182, 182, 0.35);*/
    /*background: rgba(255, 255, 255, 0.8);*/
    /*border-radius: 10px;*/
    padding: 10px;

    .svg-icon{
      font-size: 90px;
    }
    transform:translateX(300px);
    transition: transform 0.3s ease-in-out 0s;
    &.show{
      transform:translateX(0);
    }
  }

  .call-center-button-hidden {
    color:#034bf4;
    z-index: 200;
    position: fixed;
    right: 10px;
    bottom: 100px;
    background: rgba(248,249,255,0.5);
    box-shadow: 0 10px 30px rgb(96 131 254 / 7%), 0 4px 8px rgb(96 131 254 / 4%), 0 2px 4px rgb(96 131 254 / 3%);
    border-radius: 8px;
    backdrop-filter: blur(10px);
    border: 1px solid #dfe7f9;

    padding: 10px;
    transform:translateX(300px);
    transition: transform 0.3s ease-in-out 0s;
    &.show{
      transform:translateX(0);
    }
  }
}


</style>
