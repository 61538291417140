
const state = {
  fullWidth: undefined,
};

const mutations = {

  SET_fULL_WIDTH: (state, data) => {
    // console.log(data);
    state.fullWidth = data;
  },
};

const actions = {
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
