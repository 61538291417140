<template>
  <div class = "message-content3" >
    <div class="msg-header">
      <span>
      <svg-icon icon-class="wechat" class="wechat" ></svg-icon> 微信扫一扫</span>
      <!--<el-button class="header-button" type="text" icon="el-icon-close" @click="closeWindow"></el-button>-->
    </div>
    <div class="qr-box">
      <img class="qr-code" :src="$previewFile(QRCallWechatSite)"/>
    </div>

  </div>


</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: "message-content3",
    components:{
    },
    computed:{
      ...mapGetters([
        'QRCallWechatSite',
      ]),
    },
    props:{
      combinationData:{
        type:Object,
        default:()=>{
          return {
          }
        },
      }
    },
    data(){
      return{

      }
    },
    methods:{

      closeWindow(){
        // console.log(this.QRCallWechatSite);
        this.$emit('closeWindow');//发射事件
      },
    },
  }
</script>

<style lang="scss" scoped>
  .message-content3{
    background: rgba(248,249,255,0.5);
    /*background: rgba(255,255,255);*/
    box-shadow: 0 10px 30px rgb(96 131 254 / 7%), 0 4px 8px rgb(96 131 254 / 4%), 0 2px 4px rgb(96 131 254 / 3%);
    border-radius: 8px;
    backdrop-filter: blur(10px);
    border: 1px solid #dfe7f9;
    height: 260px;
    width: 200px;
    position: relative;

    .msg-header{
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      font-weight: 500;
      padding: 0 10px;
      color: black;
      .wechat{
        color: #13ce66;
        margin-right: 5px;
      }
    }
    .header-button{
      /*color: red;*/
      padding: 0;
      margin:5px -5px 0 0;
      font-size: 20px;
      float: right;
    }
    .qr-box{
      padding: 5px;
    }
    .qr-code{
      width: 100%;
    }
  }
</style>
