<template>
  <div id="app" class="app-main">
    <call-center></call-center>
    <Header></Header>
    <router-view/>
    <Footer></Footer>
    <GoTop></GoTop>
  </div>
</template>

<script>
  import CallCenter from '@/views/callCenter'
  export default {
    name: "APP",
    components:{
      CallCenter
    },
    data() {
      return {
      }
    },

    created() {
      this.$store.commit('page/SET_fULL_WIDTH',document.documentElement.clientWidth);
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize)
    },

    methods: {
      handleResize(event) {
        this.$store.commit('page/SET_fULL_WIDTH',document.documentElement.clientWidth);
        // this.fullWidth = document.documentElement.clientWidth
      },
    }
  }
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*color: #2c3e50;*/
  /*height: 100%;*/
  /*overflow-y: auto;*/
}

</style>
