<template>
  <!-- 头部整体盒子 -->
  <div id="header" class="container-fuild">
    <!-- 头部顶部 -->
    <!--<div class="header-top container-fuild hidden-xs">-->
      <!--<div class="container">-->
        <!--<div class="server pull-left">-->
          <!--<span class="glyphicon glyphicon-earphone"></span>{{phoneSite}}-->
          <!--<span class="glyphicon glyphicon-envelope"></span>{{emailSite}}-->
          <!--<span class="glyphicon glyphicon-time"></span>7x24小时为您服务-->
          <!--<div class="shejiao pull-right pointer" @click="phoneNavClick(6,navList[6])">-->
            <!--<span class="glyphicon glyphicon-hand-right"></span>-->
            <!--赶快联系我们吧！-->
            <!--&lt;!&ndash;<span class="glyphicon glyphicon-hand-left"></span>&ndash;&gt;-->
          <!--</div>-->
        <!--</div>-->
        <!--<div class="shejiao pull-right pointer" @click="clickSystem">-->
          <!--<svg-icon icon-class="平台管理" style="margin-right: 5px"></svg-icon>-->
          <!--前往电力平台-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->
    <!-- 电脑导航 -->
    <div class="header-nav container hidden-xs">
      <!-- 导航logo -->
      <div class="header-nav-logo">
        <img :src="$previewFile(logoSite) ">
      </div>
      <el-button class="slogan-button" size="small" type="primary" @click="clickSystem">
        <svg-icon icon-class="平台管理" style="margin-right: 5px"></svg-icon>
        前往电力平台
      </el-button>
      <!-- 导航内容 -->
      <ul class="header-nav-wrapper">
        <li
          v-for="(item,index) in navList"
          :key="index"
          :class="index===navIndex?'active':''"
          @click="navClick(index,item.name)"
        >
          <router-link :to="{path:item.path,query:item.query}">
            {{item.name}}
            <span v-if="item.children.length>0" class="glyphicon glyphicon-menu-down"></span>
            <i class="underline"></i>
          </router-link>
          <dl v-if="item.children.length>0">
            <dt v-for="(i,n) in item.children" :key="n">
              <router-link :to="{path:i.path,query:i.query}">{{i.name}}</router-link>
            </dt>
          </dl>
        </li>
      </ul>

    </div>
    <!-- 手机导航 -->
    <div class="header-nav-m container-fuild visible-xs">
      <div class="header-nav-m-logo">
        <img class="center-block" :src="$previewFile(logoSite) " alt="logo">
      </div>
      <!-- 导航栏 -->
      <div class="header-nav-m-menu text-center"
           data-target="#menu"
           data-toggle="collapse" @click="menuClick">
        {{menuName}}
        <div class="header-nav-m-menu-wrapper">
          <span :class="menuClass"></span>
        </div>
        <!-- 导航内容 -->
        <ul id="menu" class="header-nav-m-wrapper collapse">
          <li
            v-for="(item,index) in navPhoneList"
            :key="index"
            :class="index===navIndex?'active':''"
            @click="phoneNavClick(index,item)"
            data-toggle="collapse"
            data-target="#menu">
            <a>
              {{item.name}}
              <i class="underline"></i>
            </a>
            <!--<router-link :to="item.path">-->
            <!--</router-link>-->
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import {judgmentType} from "@/utils/dataHandle";
export default {
  name: "Header",
  data() {
    return {

      navIndex: sessionStorage.getItem('navIndex') ? sessionStorage.getItem('navIndex') : 0,
      menuName: "首页",
      menuClass: "glyphicon glyphicon-menu-down",
      // navList:
    };
  },
  computed:{
    ...mapGetters(['phoneSite','emailSite','logoSite','comboList']),

    navList(){
      let tem = [
            {
              name: "首页",
              path: "/",
              children: []
            },
            {
              name: "产品",
              path: "/service",
              children: []
            },
            // {
            //   name: "售电套餐",
            //   path: "/product",
            //   query:{id:1},
            //   children: [
            //   ]
            // },
            {
              name: "公告",
              path: "/notice",
              children: []
            },
            {
              name: "新闻动态",
              path: "/news",
              children: []
            },
            // {
            //   name: "新闻公告",
            //   path: "/news",
            //   showChildren:true,
            //   children: [
            //     {
            //       name: "新闻动态",
            //       path: "/news"
            //     },
            //     {
            //       name: "公司公告",
            //       path: "/notice"
            //     }
            //   ]
            // },
            {
              name: "关于我们",
              path: "/aboutus",
              showChildren:true,
              children: [
                // {
                //   name: "关于我们",
                //   path: "/aboutus/companyintroduction"
                // },
                // {
                //   name: "公司发展",
                //   path: "/aboutus/companydevelop"
                // }
              ]
            },
            {
              name: "工作机会",
              path: "/jobchance",
              children: []
            },
            {
              name: "联系我们",
              path: "/contactus",
              children: []
            }
          ];


      // let reList=[];
      // let list = this.comboList;
      // if(judgmentType(list) === 'array'){
      //   for (let item of list){
      //     reList.push({
      //       name: item.title,
      //       path: "/product",
      //       query:{id:item.id}
      //     })
      //   }
      // }
      // tem[2].children = reList;
      // tem[2].query = reList.length>0? reList[0].query:{id:undefined};
      return tem;
    },

    navPhoneList(){

      let tem = [];
      for(let item of this.navList){
        if(item.showChildren){
          for(let item2 of item.children){
            tem.push(item2)
          }

        }
        else{

          tem.push(item)
        }
      }


      return tem
    }
  },
  created(){
    this.pageInit();
  },


  methods: {

    pageInit(){
    },

    navClick(index, name) {
      this.navIndex = index;
      sessionStorage.setItem('navIndex',index);
      this.menuName = name;
    },
    phoneNavClick(index,item){
      let name = item.name;
      this.navIndex = index;
      sessionStorage.setItem('navIndex',index);
      this.menuName = name;
      this.$router.push({path:item.path,query:item.query});
    },
    menuClick() {
      if (this.menuClass === "glyphicon glyphicon-menu-down") {
        this.menuClass = "glyphicon glyphicon-menu-up";
      } else {
        this.menuClass = "glyphicon glyphicon-menu-down";
      }
    },
    clickSystem(){
      window.open('https://iot.lq-tc.com/');
      // this.$router.push({path:item.path,query:item.query});
    }
  }
};
</script>
<style lang="scss" scoped>
/* 顶部 */
#header {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: #ffffff;
  transition: all ease 0.6s;
  z-index: 2;
  box-shadow: 0 2px 10px 0 rgb(180 189 221 / 25%);
}
#header .header-top {
  height: 50px;
  color: $-color-font-3;
  font-size: 12px;
  line-height: 50px;
  background: $-color-background;
}
/* 顶部的图标 */
#header .header-top span {
  margin: 0 8px;
}
/* 导航栏 */
#header .header-nav {
  height: 65px;
  /*padding: 0 30px;*/
  .slogan-button{
    margin: 17px 0 17px 20px;
    float: right;
  }

}
/* 导航栏logo */
#header .header-nav .header-nav-logo {
  /*width: 80px;*/
  height: 100%;
  float: left;
  position: relative;
}
/* 导航栏logo图片 */
#header .header-nav .header-nav-logo img {
  max-width: 180px;
  max-height: 46px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
/* 导航栏 导航容器 */
#header .header-nav-fixed .header-nav-wrapper {
  line-height: 50px;
}
#header .header-nav .header-nav-wrapper {
  line-height: 65px;
  float: right;
  margin: 0;
  max-width: 800px;
}
/* 导航栏 每个导航 */
#header .header-nav .header-nav-wrapper > li {
  float: left;
  margin: 0 15px;
  position: relative;
}
/* 导航栏 每个导航下面的 a 链接 */
#header .header-nav .header-nav-wrapper > li > a {
  color: $-color-font-1;
  font-size: 15px;
  /*font-weight: bold;*/
  padding: 15px 0;
  position: relative;
}
/* 导航栏 每个导航下面的 a 链接的下划线 */
#header .header-nav .header-nav-wrapper > li > a > i {
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  opacity: 0;
  transition: all 0.6s ease;
  background-color: $-color-font-1-light;
}
/* 导航栏 每个导航下面的 a 链接的右侧小三角 */
#header .header-nav .header-nav-wrapper > li > a > span {
  font-size: 12px;
  transition: transform ease 0.5s;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover {
  color: $-color-font-1-light;
  text-decoration: none;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去下划线的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover .underline {
  opacity: 1;
  width: 100%;
  left: 0;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去三角标的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover span {
  transform: rotate(180deg);
}
/* 导航栏 每个导航下面的 a 链接 鼠标点击后的样式 */
#header .header-nav .header-nav-wrapper > li.active > a {
  color: $-color-font-1-light;
  text-decoration: none;
  border-bottom: 2px solid $-color-font-1-light;
}
/* 导航栏 每个导航下面的二级导航容器 */
#header .header-nav .header-nav-wrapper > li > dl {
  display: none;
  position: absolute;
  width: 168px;
  top: 80%;
  left: 0;
  z-index: 999999;
  box-shadow: 0 0 3px 1px #ccc;
  background: #fff;
}
/* 导航栏 每个导航下面的二级导航容器的每个导航 */
#header .header-nav .header-nav-wrapper > li > dl > dt {
  width: 100%;
  /*padding: 10px;*/
  border-bottom: 1px solid #ccc;
}


#header .header-nav .header-nav-wrapper > li > dl > dt > a{
  display: block;
  padding: 10px;
  color: $-color-font-1-light;
}

/* 导航栏 每个导航下面的二级导航容器的每个导航 当鼠标滑上时的样式*/
#header .header-nav .header-nav-wrapper > li > dl > dt > a:hover {
  text-decoration: none;
}
/* 导航栏 滑上一级导航显示二级导航 */
#header .header-nav .header-nav-wrapper > li:hover dl {
  display: block;
}
#header .header-nav .header-nav-wrapper > li > dl > dt:hover {
  cursor: pointer;
  background: $-color-font-1-background;
}

@media screen and (min-width: 768px) and (max-width: 996px) {
  #header{
    .header-nav {
      height: 65px;
      padding: 0 5px;
      .slogan-button{
        margin: 17px 0 17px 0px;
        float: right;
      }
      .header-nav-wrapper{
        li{
          margin: 0 11px;
        }
      }
    }
  }
}
@media screen and (max-width: 997px) {
  #header .header-nav-m {
    position: relative;
  }
  /* 导航栏logo容器 */
  #header .header-nav-m .header-nav-m-logo {
    height: 65px;
    position: relative;
  }
  /* 导航栏logo图片 */
  #header .header-nav-m .header-nav-m-logo img {
    max-width: 150px;
    max-height: 45px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  /* 导航栏  菜单容器 */
  #header .header-nav-m .header-nav-m-menu {
    color: #fff;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    background: $-color-theme;
    position: relative;
  }
  /* 导航栏 菜单图标 */
  #header .header-nav-m .header-nav-m-menu-wrapper {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -20px;
    width: 50px;
    height: 40px;
    color: #fff;
    z-index: 999999;
    font-size: 12px;
  }
  /* 导航栏 */
  #header .header-nav-m .header-nav-m-wrapper {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    background: $-color-background;//$-color-theme;
    /*border-bottom: 1px solid #c4e4f1;//#ccc;*/
    box-shadow: 0 10px 30px rgb(96 131 254 / 7%), 0 4px 8px rgb(96 131 254 / 4%), 0 2px 4px rgb(96 131 254 / 3%);
    z-index: 9999999;
  }
  /* 导航栏 每个导航 */
  #header .header-nav-m .header-nav-m-wrapper > li {
    height: 40px;
    line-height: 40px;
/*    border-bottom: 1px solid $-color-background;//#ccc;*/
  }
  /* 导航栏 每个导航下面的 a 链接 */
  #header .header-nav-m .header-nav-m-wrapper > li > a {
    color: $-color-font-3;//#fff;
    font-size: 15px;
    font-weight: bold;
    padding: 15px 0;
    position: relative;
  }
  /* 导航栏 每个导航下面的 a 链接的右侧小三角 */
  #header .header-nav .header-nav-wrapper > li > a > span {
    font-size: 10px;
  }
}
</style>
