import Vue from 'vue'
import App from './App.vue'


/* 路由 */
import router from './router'

/* 存储 */
import store from './store'

import './permission' // permission control

/* axios */
// import axios from './api'
// import api from './api/api'
// Vue.prototype.http = axios;
// Vue.prototype.api = api;

/* swiper */
import 'swiper/dist/css/swiper.min.css';

/* 重置样式 */
import './assets/css/reset.min.css'

/* jquery */
import 'jquery'



/* bootstarp */
import './assets/css/bootstrap.min.css'
import './assets/js/bootstrap.min'

import './styles/index.scss' // global css

/* animate.css */
import 'animate.css'

/* ElementUI */
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './styles/element-variables.scss'
Vue.use(ElementUI);


// 挂载全局通用函数
import {parseTime} from "@/utils/custom";
import { previewFile } from '@/api/app';
Vue.prototype.$parseTime = parseTime; // 格式化时间戳显示
Vue.prototype.$previewFile = previewFile; // 预览文件 展示图片等时候使用

import './icons' // icon

/* 头部组件 */
import Header from './components/Header'
Vue.component(Header.name,Header);


/* 尾部组件 */
import Footer from './components/Footer'
Vue.component(Footer.name,Footer);

/* 回到顶部 */
import GoTop from './components/GoTop'
Vue.component(GoTop.name,GoTop);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
