import {getComboList, getConfig} from "@/api/api";
import { Message } from 'element-ui'
import {chainToMap, judgmentType} from "@/utils/dataHandle";


const state = {
  site: undefined,
  emailSite:undefined,
  sloganSite:undefined,
  phoneSite:undefined,
  introductionSite:undefined,
  geoSite:undefined,
  copyrightSite:undefined,
  faxSite:undefined,
  wechatSite:undefined,
  addressSite:undefined,
  qqSite:undefined,
  telephoneSite:undefined,
  logoSite:undefined,
  logoWhiteSite:undefined,
  titleSite:undefined,
  companyNameSite:undefined,
  companyImageSite:undefined,
  comboInfoSite:undefined,
  comboInfoImgSite:undefined,
  intellectualPropertySite:undefined,
  developmentVisionSite:undefined,
  companyVideoSite:undefined,
  businessLicenseSite:undefined,

  QRWechatSite:undefined,
  QRXiaochengxuSite:undefined,
  QRIosSite:undefined,
  QRAndroidSite:undefined,
  QRCallWechatSite:undefined,

  comboList:undefined,
};

const mutations = {
  SET_SITE: (state, data) => {
    state.site = data;
    for(let key in data){
      if(Object.hasOwnProperty.call(data,key) && Object.hasOwnProperty.call(state,key+'Site')){
        state[key+'Site'] = data[key].val;
      }
    }
  },

  SET_COMBO: (state, data) => {
    state.comboList = data;
  },
};

const actions = {
  getSiteInfo({ commit }, data){
    return new Promise((resolve, reject) => {
      getConfig({module:'site'}).then(response => {
        if (response.code === 200) {
          let data;
          if( judgmentType(response.data) === 'array'){
            data = chainToMap(response.data,'keyName');
          }
          commit('SET_SITE',data);
          resolve(data);
        } else {
          Message.error(response.msg);
          reject(error);
        }
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  },

  getComboList({ commit }, data){
    return new Promise((resolve, reject) => {
      getComboList().then(response => {
        if (response.code === 200) {
          let data = response.data;
          commit('SET_COMBO',data);
          resolve(data);
        } else {
          Message.error(response.msg);
          reject(error);
        }
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
