export function ratioHandle(num, ratio) {
  return parseFloat((num * ratio).toFixed(3));
}

export function deepClone(obj) {
  const _obj = JSON.stringify(obj);
  return JSON.parse(_obj);
}

export function bin2dec16(binNumber) {
  // 在这里实现你的转化方法，注意需要判断输入必须为一个非负整数

  const bin = ('000000000000000' + binNumber).slice(-16);

  let q = decimal(bin);
  q = q & 0xffff;
  // console.log("计算结果为：" + q);
  return q;
}

export function dec2bin16(decNumber) {
  // 在这里实现你的转化方法，注意需要判断输入必须为一个非负整数

  const num = decNumber & 0xffff;

  const q = binary(num);
  // console.log("计算结果为：" + q);
  return q;
}

// 实现党点击转化按钮时，将输入的十进制数字转化为二进制，并显示在result的p标签内
// Some coding

function binary(num) {
  let bin = num.toString(2);
  // console.log(bin);
  bin = ('000000000000000' + bin).slice(-16);
  return bin;
}
function decimal(num) {
  return parseInt(num, 2);
}

export function replaceChat(source, pos, newChar){
  if (pos < 0 || pos >= source.length || source.length === 0){
    return 'invalid parameters...';
  }
  const iBeginPos = 0; const iEndPos = source.length;
  const sFrontPart = source.substr(iBeginPos, pos);
  const sTailPart = source.substr(pos + 1, iEndPos);
  const sRet = sFrontPart + newChar + sTailPart;
  return sRet;
}

export function spanToDateTime(spn){
  if (spn === 0) return '';
  const time = new Date(spn * 1000);
  const YY = time.getFullYear() + '-';
  const MM = (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-';
  const DD = (time.getDate() < 10 ? '0' + (time.getDate()) : time.getDate());
  const hh = (time.getHours() < 10 ? '0' + time.getHours() : time.getHours()) + ':';
  const mm = (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()) + ':';
  const ss = (time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds());
  return YY + MM + DD + ' ' + hh + mm + ss
}
export function spanToYearMonth(spn){
  if (spn === 0) return '';
  const time = new Date(spn * 1000);
  const YY = time.getFullYear() + '-';
  const MM = (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1);
  return YY + MM
}

export function spanToDate(spn){
  const time = new Date(spn * 1000);
  const YY = time.getFullYear() + '-';
  const MM = (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-';
  const DD = (time.getDate() < 10 ? '0' + (time.getDate()) : time.getDate());
  const hh = (time.getHours() < 10 ? '0' + time.getHours() : time.getHours()) + ':';
  const mm = (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()) + ':';
  const ss = (time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds());
  return MM + DD
}

export function spanToTime(spn){
  const time = new Date(spn * 1000);
  const YY = time.getFullYear() + '-';
  const MM = (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-';
  const DD = (time.getDate() < 10 ? '0' + (time.getDate()) : time.getDate());
  const hh = (time.getHours() < 10 ? '0' + time.getHours() : time.getHours()) + ':';
  const mm = (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes());
  const ss = (time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds());
  return hh + mm
}

export function spanToUtcTime(spn){
  const time = new Date(spn * 1000);

  let hh;
  if(time.getUTCDate()>1){
    hh = (time.getUTCDate()-1)*24+time.getUTCHours() + ':'
  }
  else{
    hh = (time.getUTCHours() < 10 ? '0' + time.getUTCHours() : time.getUTCHours()) + ':';
  }

  const mm = (time.getUTCMinutes() < 10 ? '0' + time.getUTCMinutes() : time.getUTCMinutes());
  return hh + mm
}

//

export function getRuleItem(val){
  const ruleOptions = [{
    value: 0,
    label: '无报警'
  }, {
    value: 1,
    label: '>'
  }, {
    value: 2,
    label: '>='
  }, {
    value: 3,
    label: '='
  }, {
    value: 4,
    label: '<='
  }, {
    value: 5,
    label: '<'
  }, {
    value: 6,
    label: '改变'
  }];
  for (const item of ruleOptions){
    if (item.value === val){
      return item.label
    }
  }
}

export function getLevelItem(val){
  const levelOptions = [{
    value: 1,
    label: '记录'
  }, {
    value: 2,
    label: '普通'
  }, {
    value: 3,
    label: '严重'
  }];
  for (const item of levelOptions){
    if (item.value === val){
      return item.label
    }
  }
  return ''
}

export function getArgItem(store, arg, slaveTypeId){
  let note;
  return new Promise((resolve,reject) => {
    store.dispatch('GET_CFG','cfgSlaveTypes').then((res) => {
      let slave  = res.find((item) => item.id === slaveTypeId);
      if (slave && judgmentType(slave.args) === 'array'){
        let slaveArgs = slave.args;
        for(let slaveArg of slaveArgs){
          if (slaveArg.arg === arg){
            note = slaveArg.note;
            break;
          }
        }
        resolve(note)
      }
    })

  })
}
export function setObj(obj, value = undefined) {
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj,key)) {
      if (judgmentType(obj[key]) === 'object'){
        setObj(obj[key],value);
      } else {
        obj[key] = value;
      }
    }
  }
}

/**
 * @desc 设置对象中的数据,当数据中的元素值为''，null等统一转化为undefined,专用于搜索条件时对象处理
 * @param obj:待清空的对象
 * @param value:数值
 */
export function setObjNullToUndefined(obj, value = undefined) {
  for (const key in obj) {
    if (obj.hasOwnProperty.call(obj,key)) {
      if (judgmentType(obj[key]) === 'object'){
        setObjNullToUndefined(obj[key]);
      } else {
        if (!obj[key] && obj[key]!==0) obj[key] = value;
      }
    }
  }
}

/**
 * @desc 判断数据类型
 * @param o
 */
export function judgmentType(o) {
  let s = Object.prototype.toString.call(o);
  return s.match(/\[object (.*?)\]/)[1].toLowerCase();
}

/**
 * @desc 将链表转化为Map
 * @param chain 被查找的数组
 * @param id ID字段名
 * @param children:子级的key
 */
 export function chainToMap(chain,id='id',children='children'){

  const dataMap = {};
  if(!Array.isArray(chain) || chain.length === 0)return dataMap;
  function handleItem(chain){
    for(let item of chain){
      dataMap[item[id]] = item;
      if(item[children]){
        handleItem(item[children]);
      }
    }
  }
  handleItem(chain);

  return dataMap;
}

/**
 * @desc 处理链表中的数据
 * @param chain 被查找的数组
 * @param handle 过滤的方法
 * @param children:子级的key
 */
 export function chainHandleItem(chain,handle,children='children'){

  if(!handle)return;
  if(!Array.isArray(chain))return;
  let chainClone = deepClone(chain);

  function handleItem(chainInner){
    for(let item of chainInner){
      handle(item);
      if(item[children]){
        handleItem(item[children]);
      }
    }
  }
  handleItem(chainClone);

  return chainClone;
}

export function chainGetDefault(chain,key='id',children='children'){
  let a = [];
  function getItem(list) {
    if(Array.isArray(list)&& list.length > 0){
      a.push(list[0][key]);
      getItem(list[0][children]);
    }
  }
  if(Array.isArray(chain)&& chain.length > 0){
    a.push(chain[0][key]);
    getItem(chain[0][children]);
  }
  return a;
}

/**
 * @desc 获取链表中item的多级数据
 * @param chain 被查找的数组
 * @param filter 过滤的方法
 * @param children:子级的key
 */
export function chainFindItem(chain,filter,children='children'){

  if(!filter)return;
  if(!Array.isArray(chain))return;
  let url = [];
  let find = false;
  function findItem(chain,count){
    for(let i of chain){
      if(count+1 < url.length){
        url.splice(count+1,url.length - count+1);
      }
      while(count >= url.length)url.push('');
      url[count] = i;

      if(filter(i)){
        find = true;
        return i;
      }
      if(i[children]){
        let re = findItem(i[children],count + 1);
        if(re) return re;
      }
    }
  }
  findItem(chain,0);

  if(find) return url;
}

/**
 * @desc 获取完整多级数据
 * @param item:被查找的key值
 * @param options 被查找的链表数组
 * @param key:被查找的key
 * @param children:子级的key
 */
export function getFull(item,options,key='id',children='children'){
  // debugger
  if(!Array.isArray(options))return;
  let url = [];
  let find=false;
  function findItem(item,options,count){
    for(let i of options){
      if(count+1 < url.length){
        url.splice(count+1,url.length - count+1);
      }
      while(count >= url.length)url.push('');
      url[count] = i[key];
      if(i[key] === item){
        find = true;
        return item;
      }
      if(i[children]){
        let re = findItem(item,i[children],count + 1);
        if(re) return re;

      }
    }
  }
  findItem(item,options,0);

  if(find) return url;
  else return [];
}


/**
 * @desc 获取多级数据转单个数据
 * @param item:被转化的数据
 * @return 转化后的数据
 */
export function fullToLast(item){
  if(!Array.isArray(item) || item.length === 0)return undefined;
  return item[item.length-1]
}


export function timeToEle(start,end){
  return [new Date(start*1000),new Date(end*1000)]
}
export function eleToTime(arr,index){
  if(!Array.isArray(arr) || arr.length < index+1)return undefined;
  return parseInt(arr[index].getTime()/1000);
}


//寻找链表数据中含有键为某值的对象
export function findOptionsItem(value,options,key='id',children='children'){
  if(!Array.isArray(options))return;
  let url = [];
  function findItem(value,options,count){
    for(let i of options){
      if(i[key] === value){
        return i;
      }
      else if(i[children]){
        let re = findItem(value,i[children],count + 1);
        if(re) return re;
      }
    }
  }

  return findItem(value,options,0);
}




export function noNull(params) {
  if(params === 0 )return true;
  return !!params;
}

export function noNullData(data,value=undefined) {
  return noNull(data)?data:value;
}

/**
 * Remove string from Object
 * @param {Object} params
 * @returns {string}
 */
export function stringifyGetParams(params) {
  let str = "";
  if(params==null){
    return str
  }
  for(let k in params){
    str += k + "=" + params[k] + "&"
  }
  if(str.length > 0) {
    str = str.substr(0,str.length-1)
  }
  return str
}
export function getOpenMapUrl(latitude,longitude,title,address,app='baidu') {
  //说明
  //百度https://lbsyun.baidu.com/index.php?title=uri/api/web
  //高德https://lbs.amap.com/api/uri-api/guide/travel/route
  let url = '';
  if(app === 'baidu'){
    let params ={
      location:`${latitude},${longitude}`,
      title:title,
      content:address,
      output:'html',
      coord_type:'gcj02',
      src:'webapp.lq.surpervise'
    };
    url =`http://api.map.baidu.com/marker?`+stringifyGetParams(params);
  }
  else if(app === 'gaode'){
    let params ={
      position:`${longitude},${latitude}`,
      name:title,
      src:'webapp.lq.surpervise',
      coordinate:'gaode',
      callnative:0,
    };
    url =`https://uri.amap.com/marker?` + stringifyGetParams(params);
  }

  return url;
}
