import axios from 'axios'
import Qs from 'qs'
import request from '@/api/request'
import store from '@/store';
import { stringifyGetParams } from '@/utils/custom'



// get，post请求方法
export default {
  get(url, data) {
    return request({
      method: 'get',
      url,
      params: data
    })
  },
  getNoToke(url, data){
    return request({
      needToken:false,
      method: 'get',
      url,
      params: data
    })
  },
  put(url, data) {
    return request({
      method: 'put',
      url,
      data: Qs.stringify(data),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    })
  },
  post(url, data) {
    return request({
      method: 'post',
      url,
      data: Qs.stringify(data),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    })
  },
  postUpload(url, data, progressHandle = null) {
    return request({
      method: 'post',
      url,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data;charset=UTF-8'
      },
      onUploadProgress: progressHandle
    })
  },
  delete(url, data) {
    return request({
      method: 'delete',
      url,
      params: data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    })
  },
  download(url, data){
    data['_token'] = store.getters.token
    window.open(process.env.VUE_APP_BASE_API + url + '?' + stringifyGetParams(data))
  },

}
