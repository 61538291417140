import router from './router';
import store from './store';
import getPageTitle from '@/utils/get-page-title';




router.beforeEach(async(to, from, next) => {


  if(!store.getters.comboList || store.getters.site){
    await store.dispatch('app/getSiteInfo');
    await store.dispatch('app/getComboList');
  }

  document.title = getPageTitle(to.meta.title);
  next();
  // next({ ...to, replace: true });
});
//
router.afterEach(() => {

});
