
const getters = {
  emailSite: state => state.app.emailSite,
  sloganSite: state => state.app.sloganSite,
  phoneSite: state => state.app.phoneSite,
  introductionSite: state => state.app.introductionSite,
  geoSite: state => state.app.geoSite,
  copyrightSite: state => state.app.copyrightSite,
  faxSite: state => state.app.faxSite,
  wechatSite: state => state.app.wechatSite,
  addressSite: state => state.app.addressSite,
  qqSite: state => state.app.qqSite,
  telephoneSite: state => state.app.telephoneSite,
  logoSite: state => state.app.logoSite,
  titleSite: state => state.app.titleSite,
  logoWhiteSite: state => state.app.logoWhiteSite,
  companyNameSite:  state => state.app.companyNameSite,
  companyImageSite:  state => state.app.companyImageSite,
  comboInfoSite:  state => state.app.comboInfoSite,
  comboInfoImgSite:  state => state.app.comboInfoImgSite,
  intellectualPropertySite:  state => state.app.intellectualPropertySite,
  developmentVisionSite:  state => state.app.developmentVisionSite,
  companyVideoSite:  state => state.app.companyVideoSite,
  businessLicenseSite:  state => state.app.businessLicenseSite,

  QRWechatSite:  state => state.app.QRWechatSite,
  QRXiaochengxuSite:  state => state.app.QRXiaochengxuSite,
  QRIosSite:  state => state.app.QRIosSite,
  QRAndroidSite:  state => state.app.QRAndroidSite,
  QRCallWechatSite:  state => state.app.QRCallWechatSite,

  comboList: state => state.app.comboList,

  fullWidth: state => state.page.fullWidth,
};
export default getters;
