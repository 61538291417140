// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

/**
 *
   * @param {string} duration
   * @returns {string}
   */
export function formatDuration(duration) {
  if (duration <= 60) {
    return '1分钟内'
  } else if (duration < 3600) {
    // less 1 hour
    return Math.floor(duration / 60) + '分钟'
  } else if (duration < 3600 * 24) {
    return Math.floor(duration / 3600) + '小时'
  } else if (duration < 3600 * 24 * 2) {
    const day = Math.floor(duration / 86400)
    return day + '天' + Math.floor((duration - day * 86400) / 3600) + '小时'
  }
}

// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields()
  }
}

// 添加日期范围
export function addDateRange(params, dateRange) {
  var search = params
  search.beginTime = ''
  search.endTime = ''
  if (dateRange != null && dateRange !== '') {
    search.beginTime = this.dateRange[0]
    search.endTime = this.dateRange[1]
  }
  return search
}

// 回显数据字典
export function selectDictLabel(datas, value) {
  var actions = []
  Object.keys(datas).map((key) => {
    if (datas[key].dictValue === ('' + value)) {
      actions.push(datas[key].dictLabel)
      return false
    }
  })
  return actions.join('')
}

// 字符串格式化(%s )
export function sprintf(str) {
  var args = arguments;
  var flag = true;
  var i = 1
  str = str.replace(/%s/g, function() {
    var arg = args[i++]
    if (typeof arg === 'undefined') {
      flag = false
      return ''
    }
    return arg
  })
  return flag ? str : ''
}

// 转换字符串，undefined,null等转化为""
export function parseStrEmpty(str) {
  if (!str || str === 'undefined' || str === 'null') {
    return ''
  }
  return str
}

// 将json型参数转换为 string型
export function stringifyGetParams(params) {
  let str = ''
  if (params == null) {
    return str
  }
  for (const k in params) {
    str += k + '=' + params[k] + '&'
  }
  if (str.length > 0) {
    str = str.substring(0, str.length - 1)
  }
  return str
}

// unmarshalStrInts 将 "1,2,3" 型字串 转为 [1,2,3]
export function unmarshalStrInts(strInts) {
  const arr = strInts.split(',')
  return arr.map(item => {
    return parseInt(item)
  })
}

// FilterChildNode 去除被包含的下属子节点，只获取父级节点  (不会做重复过滤)
// 传入的 nodes 结构 : [ ["a","1"],["a"],["b","2","c"],["b","2"] ] => [["a"],["b","2"]]
export function FilterChildNode(nodes) {
  if (!nodes || nodes.length <= 1) {
    return nodes
  }

  const treeMap = {}
  for (const item in nodes) {
    if (!nodes[item] || nodes[item].length == 0) { continue }
    treeMap[nodes[item].join('_')] = true
  }

  const newNodes = []
  let lenItem = 0
  for (const item in nodes) {
    if (!nodes[item] || nodes[item].length == 0) { continue }
    lenItem = nodes[item].length
    if (lenItem > 1) {
      let hasParent = false
      // 只有1个元素时必然符合要求，其余情况需要判断过滤
      for (let i = 1; i < lenItem; i++) {
        if (treeMap[nodes[item].slice(0, i).join('_')]) {
          hasParent = true
          break
        }
      }
      if (hasParent) {
        continue
      }
    }
    newNodes.push(nodes[item])
  }

  return newNodes
}
